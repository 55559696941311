/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Produkty značky Frische Kraft "} description={"Frische Kraft "} seoTitle={"Frische Kraft "}>
        <Column className="css-m0an76 css-42e4bw --style2 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/7d3bbc0e1482431893113a554ab9064c_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/7d3bbc0e1482431893113a554ab9064c_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/7d3bbc0e1482431893113a554ab9064c_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/7d3bbc0e1482431893113a554ab9064c_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/7d3bbc0e1482431893113a554ab9064c_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/7d3bbc0e1482431893113a554ab9064c_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/7d3bbc0e1482431893113a554ab9064c_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/7d3bbc0e1482431893113a554ab9064c_s=3000x_.png);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390,"backgroundColor":"var(--color-blend--95)"}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Image style={{"maxWidth":490}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/35535/2d74d43f84404f2ea3470dc2f99e74b5_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/2d74d43f84404f2ea3470dc2f99e74b5_s=660x_.png 660w"} alt={""} src={"https://cdn.swbpg.com/t/35535/2d74d43f84404f2ea3470dc2f99e74b5_s=660x_.png"}>
              </Image>

              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fjzcpkw79v"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/6eec59f467fc48359e5abb025e411be4_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/6eec59f467fc48359e5abb025e411be4_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/6eec59f467fc48359e5abb025e411be4_s=660x_.png 660w"} position={null}>
              </Image>

              <Title className="title-box fs--20" content={"Frische Kraft 7,8 Kg/130 praní color&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/192c5c6277cb4d71b571dd9e75b6185f_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/35535/192c5c6277cb4d71b571dd9e75b6185f_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/192c5c6277cb4d71b571dd9e75b6185f_s=660x_.png 660w"} position={null}>
              </Image>

              <Title className="title-box fs--20" content={"Frische Kraft 7,8 Kg/130 praní univ."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/35535/20b33f4e84654cb3b3193e1009a9db9b.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} position={null}>
              </Image>

              <Title className="title-box" content={"V přípravě"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}} name={"paticka"}>
        </Column>

      </Layout>
    </ThemeWrapper>
  )
}